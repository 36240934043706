import { graphql, Link } from "gatsby"
import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import CTA from "../../components/cta"
import Tag from "../../components/tag"

import logo from "../../images/logo-only.svg"

const BlogTemplate = ({ data }) => {
  let featuredImgFluid =
    data.markdownRemark.frontmatter.featuredImage?.childImageSharp.fluid

  console.log(featuredImgFluid)

  return (
    <Layout>
      <SEO
        title={data.markdownRemark.frontmatter.title}
        description={data.markdownRemark.frontmatter.preview}
        image={featuredImgFluid?.src}
      />

      <section className="px-4 sm:px-6 py-12 md:py-20">
        <h1 className="text-white text-center text-6xl font-bold max-w-3xl mx-auto">
          {data.markdownRemark.frontmatter.title}
        </h1>
        <article className="py-8 md:py-12 prose lg:prose-lg mx-auto">
          <p className="lead text-center">
            {data.markdownRemark.frontmatter.author} &middot;{" "}
            {data.markdownRemark.frontmatter.date}
          </p>
        </article>

        {data.markdownRemark.frontmatter.video && (
          <div>
            <iframe
              className="mx-auto block"
              id="ytplayer"
              type="text/html"
              width="1280px"
              height="720px"
              src={data.markdownRemark.frontmatter.video}
              frameBorder="0"
            ></iframe>
          </div>
        )}
        <article className="py-8 md:py-12 prose lg:prose-lg mx-auto">
          <div dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }} />
        </article>
      </section>
      <div className="container mx-auto">
        <div className="flex items-center gap-6">
          <div className="h-px bg-gray-700 flex-1"></div>
          <img src={logo} className="h-12 mx-auto my-12" />
          <div className="h-px bg-gray-700 flex-1"></div>
        </div>
        <CTA />
      </div>
    </Layout>
  )
}

const UseCaseTemplate = ({ data }) => {
  const { markdownRemark } = data // data.markdownRemark holds your post data

  return (
    <Layout variant="dataPoint">
      <div className="overflow-x-hidden">
        <SEO
          title={markdownRemark.frontmatter.title}
          description={`${markdownRemark.frontmatter.title} - ${markdownRemark.frontmatter.preview}`}
        />

        <section>
          <div className="container mx-auto px-4 sm:px-6">
            <div className="py-16 xl:py-32 xl:w-2/3">
              <Tag>Data Point</Tag>
              <h1 className="font-bold text-4xl md:text-6xl my-4 md:my-8 text-white">
                {markdownRemark.frontmatter.title}
              </h1>
              <h2 className="text-light-grey text-lg md:text-xl md:my-8">
                {markdownRemark.frontmatter.preview}
              </h2>
            </div>
          </div>
        </section>

        <div className="container mx-auto grid grid-cols-1 lg:grid-cols-12 items-start">
          <div className="col-span-3 p-4 lg:py-12">
            <Link
              to="/data-points"
              className="text-blue hover:text-white transition"
            >
              &larr; Back to Data Points
            </Link>
          </div>
          <div className="col-span-6">
            <article
              dangerouslySetInnerHTML={{ __html: markdownRemark.html }}
              className="p-4 md:py-12 prose lg:prose-lg mx-auto"
            ></article>
          </div>
          <div className="col-span-3 p-14 flex flex-col gap-4 text-white text-sm sticky top-10">
            <div className="text-xs text-dark-grey">Table of Contents</div>
            {markdownRemark.headings.map(x => (
              <Link to={`#${x.id}`}>{x.value}</Link>
            ))}
          </div>
        </div>

        <div className="container mx-auto">
          <div className="flex items-center gap-6">
            <div className="h-px bg-gray-700 flex-1"></div>
            <img src={logo} className="h-12 mx-auto my-12" />
            <div className="h-px bg-gray-700 flex-1"></div>
          </div>
          <CTA />
        </div>
      </div>
    </Layout>
  )
}

export default function Template({ data }) {
  switch (data.markdownRemark.frontmatter.category) {
    case "blog":
      return <BlogTemplate data={data} />
    default:
      return <UseCaseTemplate data={data} />
  }
}

export const pageQuery = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      headings {
        id
        depth
        value
      }
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
        category
        author
        video
        preview
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
